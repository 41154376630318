import { GetService, isInternalServerToServerRequest, makeServiceMethod } from '../baseService';

export const getLegacyService: GetService = ({ path = '/api/backends' } = {}) => ({
  host: process.env.NEXT_PUBLIC_BACKENDS_API,
  routePrefix: undefined,
  through: {
    host: undefined,
    routePrefix: path,
  },
});

export const getService: GetService = ({ path = '/apps/frontend' } = {}) => ({
  host: isInternalServerToServerRequest() ? process.env.SERVER_SIDE_BACKENDS : process.env.NEXT_PUBLIC_BACKENDS_API,
  routePrefix: path,
});

export const makeLegacyCallAPIMethod = makeServiceMethod(getLegacyService);
export const makeCallAPIMethod = (internalRequestFeatureFlagsEnabled: boolean) =>
  makeServiceMethod(internalRequestFeatureFlagsEnabled ? getService : getLegacyService);
