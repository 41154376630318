import { useEffect as v } from "react";
/*! js-cookie v3.0.5 | MIT */
function p(r) {
  for (var o = 1; o < arguments.length; o++) {
    var i = arguments[o];
    for (var t in i)
      r[t] = i[t];
  }
  return r;
}
var m = {
  read: function(r) {
    return r[0] === '"' && (r = r.slice(1, -1)), r.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent);
  },
  write: function(r) {
    return encodeURIComponent(r).replace(
      /%(2[346BF]|3[AC-F]|40|5[BDE]|60|7[BCD])/g,
      decodeURIComponent
    );
  }
};
function h(r, o) {
  function i(e, s, n) {
    if (!(typeof document > "u")) {
      n = p({}, o, n), typeof n.expires == "number" && (n.expires = new Date(Date.now() + n.expires * 864e5)), n.expires && (n.expires = n.expires.toUTCString()), e = encodeURIComponent(e).replace(/%(2[346B]|5E|60|7C)/g, decodeURIComponent).replace(/[()]/g, escape);
      var c = "";
      for (var a in n)
        n[a] && (c += "; " + a, n[a] !== !0 && (c += "=" + n[a].split(";")[0]));
      return document.cookie = e + "=" + r.write(s, e) + c;
    }
  }
  function t(e) {
    if (!(typeof document > "u" || arguments.length && !e)) {
      for (var s = document.cookie ? document.cookie.split("; ") : [], n = {}, c = 0; c < s.length; c++) {
        var a = s[c].split("="), g = a.slice(1).join("=");
        try {
          var u = decodeURIComponent(a[0]);
          if (n[u] = r.read(g, u), e === u)
            break;
        } catch {
        }
      }
      return e ? n[e] : n;
    }
  }
  return Object.create(
    {
      set: i,
      get: t,
      remove: function(e, s) {
        i(
          e,
          "",
          p({}, s, {
            expires: -1
          })
        );
      },
      withAttributes: function(e) {
        return h(this.converter, p({}, this.attributes, e));
      },
      withConverter: function(e) {
        return h(p({}, this.converter, e), this.attributes);
      }
    },
    {
      attributes: { value: Object.freeze(o) },
      converter: { value: Object.freeze(r) }
    }
  );
}
var l = h(m, { path: "/" });
const f = "referralCode", d = "ridi-referral-event", R = /^https:\/\/((next|view|preflight)\.local\.)?ridibooks\.com.*$/;
async function E({
  isReferralEvent: r,
  referralEventData: o
}) {
  if (!globalThis.location || !r)
    return;
  const i = new URL(globalThis.location.href), t = Object.fromEntries(i.searchParams.entries());
  if (o) {
    const e = new Date(o.eventEndAt);
    if (e.toString() !== "Invalid Date" && /* @__PURE__ */ new Date() > e)
      if (t[f] || l.get(d)) {
        const { [f]: n, ...c } = t;
        l.remove(d, {
          domain: ".ridibooks.com",
          path: "/",
          secure: !0
        });
        const a = `${i.pathname}?${new URLSearchParams(
          c
        ).toString()}`;
        globalThis.history.replaceState({}, "", a);
      } else
        return;
  }
  if (R.test(globalThis.location.href) && (f in t && t[f] && l.set(d, t[f], {
    path: "/",
    domain: ".ridibooks.com",
    secure: !0
  }), l.get(d) && !(f in t))) {
    const e = `${i.pathname}?${new URLSearchParams({
      ...t,
      [f]: l.get(d)
    }).toString()}`;
    globalThis.history.replaceState({}, "", e);
  }
}
const x = (r, o) => {
  v(() => {
    E(r);
  }, [r.isReferralEvent, r.referralEventData, ...o]);
};
export {
  E as syncReferralCode,
  x as useSyncReferralCode
};
