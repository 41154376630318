import Agent, { HttpsAgent as KeepAliveHttpsAgent } from 'agentkeepalive';
import { Query } from 'express-serve-static-core';
import qs from 'qs';

import { GetService, isInternalServerToServerRequest, isServer, makeServiceMethod } from '../baseService';

const keepAliveHttpAgent = new Agent();
const keepAliveHttpsAgent = new KeepAliveHttpsAgent();

export const getService: GetService = ({ path = '/api/search-api' } = {}) => {
  const host = isInternalServerToServerRequest()
    ? process.env.SERVER_SIDE_BACKENDS
    : process.env.NEXT_PUBLIC_SEARCH_API;
  return {
    paramsSerializer: (params: Query) => qs.stringify(params, { arrayFormat: 'repeat' }),
    host,
    routePrefix: '/apps/search',
    through: {
      paramsSerializer: (params: Query) => qs.stringify(params, { arrayFormat: 'brackets' }),
      host: undefined,
      routePrefix: path,
    },
    timeout: 20000,
    httpAgent: isServer() ? keepAliveHttpAgent : undefined,
    httpsAgent: isServer() ? keepAliveHttpsAgent : undefined,
  };
};

export const makeCallAPIMethod = makeServiceMethod(getService);
