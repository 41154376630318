import Agent, { HttpsAgent as KeepAliveHttpsAgent } from 'agentkeepalive';

import { GetService, isInternalServerToServerRequest, isServer, makeServiceMethod } from '../baseService';

const keepAliveHttpAgent = new Agent();
const keepAliveHttpsAgent = new KeepAliveHttpsAgent();

export const getService: GetService = ({ path = '/api/book-api' } = {}) => {
  const host = isInternalServerToServerRequest() ? process.env.SERVER_SIDE_BACKENDS : process.env.NEXT_PUBLIC_BOOK_API;
  return {
    host,
    routePrefix: '/apps/book',
    through: {
      host: undefined,
      routePrefix: path,
    },
    withCredentials: false,
    timeout: 20000,
    httpAgent: isServer() ? keepAliveHttpAgent : undefined,
    httpsAgent: isServer() ? keepAliveHttpsAgent : undefined,
  };
};

export const makeCallAPIMethod = makeServiceMethod(getService);
